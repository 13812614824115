<template>
  <div class="main-wrapper">
    <title-bar
      title="Report Abuse"
      subtitle=""
    />
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 ">
                <div class="about-info">
                  <div class="about-desc">
                   <h2>Thriggle "Report Abuse" Policy</h2>
                   <p>
                     Thriggle users self-publish images, videos, posts, events, and comments subject to the Thriggle Terms of Use. If you believe that someone is violating these policies please click the report abuse red flag. Thriggle people and, or, our automated systems may also flag posts considered to be a violation of our Terms of Use.

                   </p>
                   <p>
                     Actions we will take on reported abuse
                   </p>
                   <p>
                     Thriggle will immediately cancel access to the Thriggle website and mobile apps for any user showing any type of behavior found offensive to other Thriggle users as determined by Thriggle and reported by other Thriggle users.
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
   created () {
      document.title = "Abuse - Thriggle";
  },
	components: {
		TitleBar: TitleBar
	}
};
</script>
